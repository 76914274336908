import { Controller } from "stimulus"
import * as Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';
import debounce from 'lodash/debounce';

export default class extends Controller {

  static targets = ["usersSelect"]

  connect() {
    this.initSelect();
    this.setProps();
    this.setListeners();
  };

  initSelect() {
    this.usersChoices = new Choices(this.usersSelectTarget, {
      placeholder: true,
      searchPlaceholderValue: "Membres",
      itemSelectText: "",
      noResultsText: "Aucun résultat",
      sortFn: () => {},
    });
  };

  setProps() {
    this.$searchInput = this.usersChoices.input.element;
  }

  setListeners() {
    this.$searchInput.addEventListener("keydown", debounce((e) => {
      if (this.$searchInput.value.length >= 3) {
        this.getUsers(this.$searchInput.value);
      } else {
        this.usersChoices.clearChoices();
      }
    }, 500));
  }

  getUsers(text) {
    const usersChoices = this.usersChoices
    usersChoices.ajax(function(callback) {
      fetch(`/api/users?text=${text}&id=`)
      .then(function(response) {
        response.json().then(function(data) {
          usersChoices.clearChoices();
          callback(data.users, 'value', 'label');
          usersChoices.input.element.focus()
        });
      })
      .catch(function(error) {
        console.log(error);
      });
    });
  }
}

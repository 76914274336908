import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"
import 'tiny-slider/dist/tiny-slider.css'

export default class extends Controller {

  static targets = ["controlsContainer", "slides"]

  connect() {
    this.initSlider()
  };

  disconnect() {
    if (this.slider) {
      this.slider.destroy()
    }
  }

  initSlider() {
    if (this.hasSlidesTarget) {
      this.slider = tns({
        container: this.slidesTarget,
        controlsContainer: this.controlsContainerTarget,
        items: 1,
        gutter: 15,
        responsive: {
          768: {
            gutter: 22,
            items: 2,
          },
          992: {
            items: 3,
          },
        },
        slideBy: 'page',
        speed: 1500,
        nav: false,
      })
    }
  }
}

function bindFacebookEvents() {
  if (!window.fbEventsBound) {
    $(document).on('turbolinks:before-visit', saveFacebookRoot).on('turbolinks:render', restoreFacebookRoot).on('turbolinks:load', function() {
      return typeof FB !== "undefined" && FB !== null ? FB.XFBML.parse() : void 0;
    });
    window.fbEventsBound = true;
  }
};

function saveFacebookRoot() {
  if ($('#fb-root').length) {
    window.fbRoot = $('#fb-root').detach();
  }
};

function restoreFacebookRoot() {
  if (window.fbRoot != null) {
    if ($('#fb-root').length) {
      return $('#fb-root').replaceWith(window.fbRoot);
    } else {
      return $('body').append(window.fbRoot);
    }
  }
};

function loadFacebookSDK() {
  if (!window.fbSDKLoaded) {
    if (window.axeptioSDK) {
      _loadFacebookSDK();
    } else {
      window.addEventListener('axeptio:cookies:complete', function() {
        _loadFacebookSDK();
      }, {
        once: true
      });
    }
    window.fbSDKLoaded = true;
  }
};

function _loadFacebookSDK() {
  if (window.axeptioSDK.userPreferencesManager.choices.facebook_sdk) {
    window.fbAsyncInit = initializeFacebookSDK;
    $.getScript("//connect.facebook.net/fr_FR/sdk.js")
  } else {
  }
};

// https://developers.facebook.com/docs/javascript/reference/FB.init/v11.0
function initializeFacebookSDK() {
  FB.init({
    appId: `${window.secrets.facebook_app_id}`,
    status: false,
    cookie: false,
    logging: false,
    autoLogAppEvents: false,
    xfbml: true,
    version: 'v11.0'
  });
};

export { loadFacebookSDK, bindFacebookEvents };
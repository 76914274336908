import { Controller } from "stimulus"
import { Rails } from "../shared/shared"


export default class extends Controller {

  static targets = ["list", "back", "messages", "conversations"]

  initialize() {
    this.loadInterval = setInterval(() => {this.loadMessages()}, parseInt(this.listTarget.dataset.timer)*1000)
    setTimeout(() => {this.listTarget.scrollTop = this.listTarget.scrollHeight}, 300)
  };

  loadMessages() {
    const list = this.listTarget
    Rails.ajax({
      url: `/conversations/${this.listTarget.dataset.id}/messages/load`,
      type: "get",
      data: "",
      success: function(data) {
        list.scrollTop = list.scrollHeight
      },
      error: function(data) {}
    })
  }

  back() {
    this.messagesTarget.style.display = "none";
    this.conversationsTarget.style.display = "block";
  }

  disconnect() {
    clearInterval(this.loadInterval);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "container", "imageBox"]

  connect() {
    this.setListeners();
  }

  setListeners() {
    this.fieldTarget.addEventListener("change", () => {this.displayFeedback()});
  }

  openWindow() {
    this.fieldTarget.click();
  }

  displayFeedback() {
    if (this.hasImageBoxTarget) {
      this.containerTarget.style.removeProperty('background');
      this.imageBoxTarget.innerHTML = '<i class="fas fa-check-circle"></i> Image sélectionnée';
    }
  }
}

import { Controller } from "stimulus";
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = [ "form" ];

  connect() {
    this.element.addEventListener('change', this.handleChange.bind(this));
    this.form = this.hasFormTarget ? this.formTarget : this.element.closest('form');
  } 

  disconnect() {
    this.element.removeEventListener('change', this.handleChange.bind(this));
  }

  handleChange(event) {
    if (this.form.dataset['remote'] == "true") {
      Rails.fire(this.form, 'submit');
    } else {
      this.form.submit();
    }
  }
}
import './redactor/redactor.js'
import './redactor/redactor.css'
import './redactor/_langs/fr'
import './redactor/_plugins/limiter/limiter.js'



const minimalRedactorSettings = {
  lang: 'fr',
  toolbar: false,
}

const defaultRedactorSettings = {
  lang: 'fr',
  linkTarget: '_blank',
  formatting: ['p', 'blockquote', 'h3', 'h4', 'h5'],
  buttons: ['redo', 'undo', 'format', 'bold', 'italic', 'lists', 'link']
}

const structureDescriptionRedactorSettings = {
  lang: 'fr',
  linkTarget: '_blank',
  formatting: ['p', 'blockquote', 'h2', 'h3', 'h4', 'h5'],
  buttons: ['redo', 'undo', 'format', 'bold', 'italic', 'lists', 'link']
}

const projectRedactorSettings = {
  lang: 'fr',
  formatting: ['p', 'blockquote', 'h3', 'h4', 'h5'],
  buttons: ['redo', 'undo', 'format', 'bold', 'italic', 'lists', 'link'],
  minHeight: '200px',
}

const messageRedactorSettings = {
  lang: 'fr',
  buttons: ['redo', 'undo', 'bold', 'italic', 'lists', 'link', 'image', 'file']
}

document.addEventListener("turbolinks:load", () => {

  $R('.redactor-minimal', Object.assign({}, minimalRedactorSettings, {
    minHeight: '50px',
  }));

  $R('.redactor-user-description-short', Object.assign({}, minimalRedactorSettings, {
    minHeight: '50px',
    plugins: ['limiter'],
    limiter: 120
  }));

  $R('.redactor-project-description-short', Object.assign({}, minimalRedactorSettings, {
    minHeight: '50px',
    plugins: ['limiter'],
    limiter: 200
  }));

  $R('.redactor-default', Object.assign({}, defaultRedactorSettings, {
    minHeight: '200px',
  }));

  $R('.redactor-project', Object.assign({}, projectRedactorSettings))
  // Add default text if description empty
  if ($R('.redactor-project', 'source.getCode') == "") {
    const projectDescription = `<h3><strong>Pitch</strong></h3>\r\n<p><br></p><p></p>\r\n
      <h3>Postes recherchés</h3>\r\n<p></p>\r\n<p></p>\r\n
      <h3>Matériel et accessoires recherchés</h3>\r\n<p></p>\r\n<p></p>\r\n
      <h3>Dates et lieux de tournage</h3>\r\n<p></p><p></p>\r\n
      <h3>Stade actuel d'avancement</h3>\r\n<p></p><p></p>\r\n
      <h3>Synopsis</h3>\r\n<p></p><p></p>\r\n
      <h3>Note d'intention</h3>\r\n<p></p><p></p>\r\n
      <h3>Extrait de la continuité dialoguée</h3>\r\n<p></p><p></p>\r\n
      <h3>Matériel de tournage disponible</h3>\r\n<p></p><p></p>\r\n
      <h3>Liens utiles et informations complémentaires</h3>\r\n<p></p><p></p>\r\n
      <h3>Remerciements</h3>\r\n<p></p><p></p>
    `
    $R('.redactor-project', 'insertion.insertHtml', projectDescription)
  }

  $R('.redactor-message', Object.assign({}, messageRedactorSettings, {
    minHeight: '200px',
  }));

  $R('.redactor-structure-description', Object.assign({}, structureDescriptionRedactorSettings))
})

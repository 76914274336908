import { Controller } from "stimulus"
import * as Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';

export default class extends Controller {
  static targets = ["selectField"]

  connect() {
    new Choices(this.selectFieldTarget, {
      itemSelectText: "",
      noResultsText: "Aucun résultat",
      sortFn: () => {},
      removeItems: true,
      removeItemButton: true,
    });

  };
}

import { Controller } from "stimulus"
import { Rails } from "../shared/shared"


export default class extends Controller {

  static targets = ["link"];

  followLink() {
    this.linkTarget.click()
  }

}

import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"
import 'tiny-slider/dist/tiny-slider.css'

export default class extends Controller {

  static targets = ["controlsContainer", "slides"]

  connect() {
    this.initSlider()
  };

  disconnect() {
    if (this.slider) {
      this.slider.destroy()
    }
  }

  initSlider() {
    if (this.hasSlidesTarget) {
      this.slider = tns({
        container: this.slidesTarget,
        controlsContainer: this.controlsContainerTarget,
        items: 1,
        slideBy: 'page',
        gutter: 20,
        speed: 1000,
        nav: false,
        responsive: {
          768: {
            items: 2
          }
        }
      })
    }
  }
}

import { Controller } from "stimulus"
import * as Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';

export default class extends Controller {
  static targets = ["skills", "categories", "users", "statuses", "levels"]

  connect() {
    this.initSelect();
  };

  initSelect() {

    if (this.hasCategoriesTarget) {
      new Choices(this.categoriesTarget, {
        itemSelectText: "",
        noResultsText: "Aucun résultat",
        sortFn: () => {},
        removeItems: true,
        removeItemButton: true,
      });
    }

    if (this.hasSkillsTarget) {
      new Choices(this.skillsTarget, {
        itemSelectText: "",
        noResultsText: "Aucun résultat",
        sortFn: () => {},
      })
    }

    if (this.hasStatusesTarget) {
      new Choices(this.statusesTarget, {
        itemSelectText: "",
        noResultsText: "Aucun résultat",
        sortFn: () => {},
      })
    }

    if (this.hasLevelsTarget) {
      new Choices(this.levelsTarget, {
        itemSelectText: "",
        noResultsText: "Aucun résultat",
      })
    }
  }
}

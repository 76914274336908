const images = require.context('../images', true)

// const imagePath = (name) => images(name, true)
import '../application/stylesheets/application.scss';
import '../shared/shared'

import '../application/javascripts/pagy'

global.Pagy = Pagy

import '../application/javascripts/facebook'

// StimulusJS
import "../controllers/index"

import Collapse from "bootstrap/js/src/collapse"
import Dropdown from "bootstrap/js/src/dropdown"

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["user", "project"]

  clickUser() {
    if (event.target.tagName != "A") {
      this.userTarget.click()
      event.stopPropagation()
    }
  }
}

import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import './redactor'

Rails.start();
Turbolinks.start()

export {Rails}

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

// permet de désactiver le scroll si une condition est vraie
// https://github.com/turbolinks/turbolinks/issues/591
Turbolinks.Visit.prototype.loadResponse = function () {
  return null != this.response ? this.render(function () {
      var t, e;
      var scroller = this.performScroll;
      if ((new URL(this.request.url)).pathname === (new URL(this.request.referrer)).pathname && this.request.url.includes("scroll_to_form")) {
        scroller = function () {
        }
      }
      return this.cacheSnapshot(), this.request.failed ? (this.controller.render({
          error: this.response
      }, scroller), "function" == typeof (t = this.adapter).visitRendered && t.visitRendered(this), this.fail()) : (this.controller.render({
          snapshot: this.response
      }, scroller), "function" == typeof (e = this.adapter).visitRendered && e.visitRendered(this), this.complete());
  }) : void 0;
};

// permet d'intercepter un form en get soumis en ajax et remplacer le comportement normal avec un Turbolinks.visit
document.addEventListener('turbolinks:load', function(event) {
  for (let form of document.querySelectorAll('form[method=get][data-turbolinks-form][data-remote="true"]')) {
    form.addEventListener('ajax:beforeSend', function (event) {
      const detail = event.detail,
            xhr = detail[0], options = detail[1];
      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  }
});
import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["finishedRadio", "notFinishedRadio", "labfilmsLabelRadio", "notLabfilmsLabelRadio", "statusSelectContainer"]

  connect() {
    if (this.hasfinishedRadioTarget && this.hasNotFinishedRadioTarget) {
      this.onFinishedChange();
    } 
    if (this.hasLabfilmsLabelRadioTarget && this.hasNotLabfilmsLabelRadioTarget) {
      this.onLabfilmsLabelChange();
    } 
  }

  onFinishedChange() {
    if(!this.finishedRadioTarget.checked && this.notFinishedRadioTarget.checked) {
      this.statusSelectContainerTarget.style.display = 'block';
    } else {
      this.statusSelectContainerTarget.style.display = 'none';
    }
  }

  onLabfilmsLabelChange() {
    if(!this.notLabfilmsLabelRadioTarget.checked && this.labfilmsLabelRadioTarget.checked) {
      this.statusSelectContainerTarget.style.display = 'block';
    } else {
      this.statusSelectContainerTarget.style.display = 'none';
    }
  }
}

import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"
import 'tiny-slider/dist/tiny-slider.css'

export default class extends Controller {

  static targets = ["prevBtn", "nextBtn", "navContainer", "slides"]

  connect() {
    this.initSlider()
  };

  disconnect() {
    if (this.slider) {
      this.slider.destroy()
    }
  }

  initSlider() {
    if (this.hasSlidesTarget) {
      this.slider = tns({
        container: this.slidesTarget,
        prevButton: this.prevBtnTarget,
        nextButton: this.nextBtnTarget,
        navContainer: this.navContainerTarget,
        items: 1,
        speed: 1000,
        rewind: true
      })
    }
  }
}

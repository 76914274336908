import { Controller } from "stimulus"
import { Rails } from "../shared/shared"
import * as Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';
import Sortable from 'sortablejs';


export default class extends Controller {

  static targets = ["projectTemplate", "projectSelect", "addProjectBtn", "projectIdsField", "projectsContainer", "header" ];

  connect() {
    this.projectSelectChoices = new Choices(this.projectSelectTarget, {
      itemSelectText: "",
      noResultsText: "Aucun résultat",
      sortFn: () => {},
    });

    this.projectSelectTarget.addEventListener('addItem', this.onProjectSelectChange.bind(this));

    this.sortable = Sortable.create(this.projectsContainerTarget, {
      items: '[data-project-id]'
    });

    this.toggleHeaderVisibility();
  }

  disconnect() {
    this.projectSelectChoices.destroy();
    this.projectSelectTarget.removeEventListener('addItem', this.onProjectSelectChange.bind(this));
    this.sortable.destroy();
  }

  addProject(event) {
    const details = this.projectSelectChoices.getValue();
    if (details.value) {
      const content = this.projectTemplateTarget.innerHTML.replace(/TEMPLATE_PROJECT_NAME/g, details.label).replace(/TEMPLATE_PROJECT_ID/g, details.value);
      this.projectsContainerTarget.insertAdjacentHTML('beforeend', content);
      this.projectSelectChoices.setValue("");
    }
    this.toggleHeaderVisibility();
  }

  removeProject(event) {
    const projectElem = event.target.closest('[data-project-id]');
    projectElem.parentNode.removeChild(projectElem);
    this.toggleHeaderVisibility();
  }

  onProjectSelectChange(event) {
    if(event.detail.value) {
      this.addProjectBtnTarget.disabled = false;
    } else {
      this.addProjectBtnTarget.disabled = true;
    }
  }

  setProjectPositionsBeforeSubmit(event) {
    const projectIds = [];
    this.projectsContainerTarget.querySelectorAll('[data-project-id]').forEach((projectElem) => {
      projectIds.push(projectElem.dataset.projectId);
    })
    this.projectIdsFieldTarget.value = projectIds;
    return true;
  }

  toggleHeaderVisibility() {
    if(this.projectsContainerTarget.querySelectorAll('[data-project-id]').length != 0)
      this.headerTarget.style.display = 'grid';
    else {
      this.headerTarget.style.display = 'none';
    }
  }
}

import { Controller } from "stimulus"
import { tns } from "tiny-slider/src/tiny-slider"
import 'tiny-slider/dist/tiny-slider.css'

export default class extends Controller {

  static targets = ["navControls", "sidebarProjects", "featuredProjects"]

  connect() {
    this.setProps()
    this.setListeners()
    this.initSliders()
  };

  disconnect() {
    this.sliders.forEach((slider) => {
      if(slider) {
        slider.destroy();
      }
    });
  }

  setProps() {
  }

  setListeners() {
  }

  initSliders() {
    this.sliders = [];

    if (this.hasSidebarProjectsTarget) {
      const sidebarProjectsSlider = tns({
        container: this.sidebarProjectsTarget,
        controlsContainer: this.navControlsTarget,
        navAsThumbnails: true,
        nav: false,
        mouseDrag: true,
      });
      this.sliders.push(sidebarProjectsSlider);
    }

    return this.sliders;
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ];

  connect() {
    this.pop();
  }

  pop() {
    if (this.hasMessageTarget) {
      $(this.messageTarget).hide().delay(500).fadeIn(700).delay(7000).fadeOut(1000)
    }
  }
}

import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "trigger", "container" ]
  static classes = [ "toggled" ]
  static values = {
    isOpen: Boolean
  }

  connect() {
    useClickOutside(this);
    if (this.isOpenValue) {
      this.toggle();
    }
  }

  clickOutside() {
    this.containerTarget.classList.remove(this.toggledClass);
  }

  toggle() {
    this.containerTarget.classList.toggle(this.toggledClass);
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  connect() {
  }

  triggerRecatpchaIfFormIsValid(event) {
    console.log('triggerRecatpchaIfFormIsValid');
    if(this.element.checkValidity()) {
      grecaptcha.execute();
    } else {
      this.element.reportValidity();
    }
  }

}

window.submitInvisibleRecaptchaFormOnRegistrationsNew = function() {
  document.getElementById("new_user").submit()
}